import React from "react";
import httpClient from "../../api/httpClient";
import SearchBox from "./SearchBox";
import { SearchBoxContorller } from "./controllers";
import { IconSearch } from "@tabler/icons-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

interface GlobalSearchStandaloneProps {
  accessToken: string;
  organizationId: string;
  organizationEndpoints: {
    platform: string;
    analytics: string;
    search: string;
  };
  locale: string;
}

const GlobalSearchStandalone = ({
  accessToken,
  organizationId,
  organizationEndpoints,
  locale,
}: GlobalSearchStandaloneProps) => {
  const [open, setOpen] = React.useState(false);

  const onSearchSubmit = (value: string) => {
    setOpen(false);
    window.location.href = `/global-search#q=${value}`;
  };

  const configuration = {
    search: { pipeline: "Global Search" },
    accessToken,
    organizationId,
    organizationEndpoints,
    renewAccessToken() {
      return httpClient({
        url: "/graphql",
        method: "post",
        data: JSON.stringify({
          query: "mutation { coveoJwtCreate(input: {}) { jwt } }",
        }),
      }).then((response) => response.data.data.coveoJwtCreate.jwt);
    },
  };

  const { searchBox } = SearchBoxContorller(configuration);

  return (
    <div className="global-search-standalone">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger className="bg-transparent border-none">
          <span className="mt-1 flex items-end">
            <IconSearch className="w-5 h-5 mr-2" /> Search
          </span>
        </PopoverTrigger>
        <PopoverContent className="tw-popover" side="bottom" align="end">
          <SearchBox controller={searchBox} onSearchSubmit={onSearchSubmit} />
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default GlobalSearchStandalone;
