import {
  buildFacet,
  buildResultList,
  buildSearchBox,
  SearchEngineConfiguration,
} from "@coveo/headless";
import { headlessEngine } from "./Engine";

export const SearchBoxContorller = (
  configuration: SearchEngineConfiguration,
) => {
  const engine = headlessEngine(configuration);

  return {
    searchBox: buildSearchBox(engine),
    facet: buildFacet(engine, {
      options: { field: "source" },
    }),
    resultList: buildResultList(engine),
  };
};
