import React from "react";
import { SearchBox as SearchBoxController, Suggestion } from "@coveo/headless";
import { useEffect, useState, FunctionComponent } from "react";
import { Input } from "@/components/ui/input";
import { IconSearch } from "@tabler/icons-react";

interface SearchBoxProps {
  controller: SearchBoxController;
  onSearchSubmit: (value: string) => void;
}

const SearchBox: FunctionComponent<SearchBoxProps> = ({
  controller,
  onSearchSubmit,
}) => {
  const [state, setState] = useState(controller.state);

  useEffect(() => {
    controller.subscribe(() => setState(controller.state));
  }, [controller]);

  return (
    <>
      <div className="relative">
        <IconSearch className="absolute left-3 top-2 w-4 h-4 text-gray-500" />
        <Input
          placeholder="Search..."
          value={state.value}
          onChange={(e) => controller.updateText(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && onSearchSubmit(state.value)}
          className="pl-10"
        />
      </div>
      {state.suggestions.length > 0 && (
        <div className="shadow-lg">
          {state.suggestions.map((suggestion) => (
            <div
              className="cursor-pointer py-2 px-4 w-full text-sm text-gray-800 hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800"
              data-hs-combo-box-output-item=""
              key={suggestion.rawValue}
              onKeyDown={(e) =>
                e.key === "Enter" && onSearchSubmit(suggestion.rawValue)
              }
              onClick={() => onSearchSubmit(suggestion.rawValue)}
            >
              <div className="flex justify-between items-center w-full">
                <span>{suggestion.rawValue}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SearchBox;
